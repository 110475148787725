import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Icon,
  Button,
  Box,
  IconButton,
  Card,
  Text,
  Link,
} from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { CogIcon, ExternalLinkIcon, RocketIcon } from '@nimbus-ds/icons';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useWindowWidth } from '../../hooks';

// import axiosInstance from '../../axios';

function PageDashboard() {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();

  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  const handleGoToSellersView = async () => {
    push('/setup');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleGoToLogs = async () => {
    push('/logs');
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={handleGoToConfig}
        source={<CogIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button onClick={handleGoToSellersView}>
          <Icon source={<RocketIcon />} color="currentColor" />
          Sellers
        </Button>
        <Button appearance="primary" onClick={handleGoToConfig}>
          <Icon source={<CogIcon />} color="currentColor" />
          Configuración
        </Button>
        <Button appearance="primary" onClick={handleGoToLogs}>
          <Icon source={<CogIcon />} color="currentColor" />
          Registros
        </Button>
      </Box>
    );

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <Page maxWidth="1000px">
      <Page.Header title="" buttonStack={actions} />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Card>
              <Card.Header title="Bienvenido/a" />
              <Card.Body>
                <Text as="p" fontSize="highlight">
                  example
                </Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Text as="span" fontSize="highlight">
                  <Box marginTop="2" marginBottom="2">
                    <p>example</p>
                  </Box>
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  gap="4"
                  marginTop="6"
                ></Box>
              </Card.Body>
            </Card>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}

export default PageDashboard;
